import createAxios from "./axiosHttpMarconi";
export const serviciosService = {
  getServicios,
  getServiciosByCliente,
  updateServicio,
  getServiciosDeprecado,
  nuevoServicioDeprecado,
  eliminarServicioDeprecado,
};

function getServicios(pagination, query) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/servicio?sort=codigo&order=descending`;
  if (pagination) {
    urlPath += `&page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  }
  if (query && query.tipo != "") {
    urlPath += `&tipo=${query.tipo}`;
  }
  if (query && query.codigo != "") {
    urlPath += `&codigo=${query.codigo}`;
  }
  return axios.get(urlPath);
}

function getServiciosDeprecado(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/servicio-deprecado?order=descending`;
  if (pagination) {
    urlPath += `&page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  }
  if (filters && filters.servicio != "" && filters.servicio != undefined) {
    urlPath += `&servicio=${filters.servicio}`;
  }
  if (
    filters &&
    filters.servicioDeprecado != "" &&
    filters.servicioDeprecado != undefined
  ) {
    urlPath += `&servicioDeprecado=${filters.servicioDeprecado}`;
  }
  return axios.get(urlPath);
}

function nuevoServicioDeprecado(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/servicio-deprecado`;
  return axios.post(urlPath, body);
}

function eliminarServicioDeprecado(idServicio) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/servicio-deprecado/${idServicio}`;
  return axios.delete(urlPath, idServicio);
}

function updateServicio(body) {
  const axios = createAxios();
  let franquiciadora = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${franquiciadora.extraData.franquiciadoraId}/servicio/${body._id}`;
  return axios.put(urlPath, body);
}

function getServiciosByCliente(request) {
  const axios = createAxios();
  //const tipo = localStorage.getItem('tipo');
  let urlPath = `/franquiciadora/servicio/cliente/${request.id}?no_usar_confi_servicios=True`;
  if (request.tipo && request.tipo != "") {
    urlPath += `&tipo=${request.tipo}`;
  }
  if (request.rma && request.rma != "") {
    urlPath += `&rma=${request.rma}`;
  }
  return axios.get(urlPath);
}
